/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('./fonts/roboto-v30-latin-300.woff2') format('woff2'),
    /* Modern Browsers */
    url('./fonts/roboto-v30-latin-300.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./fonts/roboto-v30-latin-300.ttf') format('truetype');
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/roboto-v30-latin-300italic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/roboto-v30-latin-300italic.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/roboto-v30-latin-300italic.ttf') format('truetype');
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/roboto-v30-latin-regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/roboto-v30-latin-regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/roboto-v30-latin-regular.ttf') format('truetype');

}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/roboto-v30-latin-italic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/roboto-v30-latin-italic.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/roboto-v30-latin-italic.ttf') format('truetype');
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/roboto-v30-latin-500.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/roboto-v30-latin-500.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/roboto-v30-latin-500.ttf') format('truetype');
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/roboto-v30-latin-500italic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/roboto-v30-latin-500italic.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/roboto-v30-latin-500italic.ttf') format('truetype');
}

/* philosopher-regular - latin */
@font-face {
  font-family: 'Philosopher';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/philosopher-v19-latin-regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/philosopher-v19-latin-regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/philosopher-v19-latin-regular.ttf') format('truetype');
}

/* philosopher-italic - latin */
@font-face {
  font-family: 'Philosopher';
  font-style: italic;
  font-weight: 400;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/philosopher-v19-latin-italic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/philosopher-v19-latin-italic.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/philosopher-v19-latin-italic.ttf') format('truetype');
}

/* philosopher-700 - latin */
@font-face {
  font-family: 'Philosopher';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/philosopher-v19-latin-700.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/philosopher-v19-latin-700.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/philosopher-v19-latin-700.ttf') format('truetype');
}

/* philosopher-700italic - latin */
@font-face {
  font-family: 'Philosopher';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(''),
    /* Super Modern Browsers */
    url('fonts/philosopher-v19-latin-700italic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/philosopher-v19-latin-700italic.woff') format('woff'),
    /* Safari, Android, iOS */
    url('fonts/philosopher-v19-latin-700italic.ttf') format('truetype');
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/material-icons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}