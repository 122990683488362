// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use './fonts.css';

// import our custom theme
@use "./theme/colors";
@use "./theme/theme";

// Override typography for all Angular Material, including mat-base-typography and all components.
$my-theme: mat.m2-define-light-theme((color: (primary: theme.$theming-material-components-primary,
      accent: theme.$theming-material-components-accent,
      warn: theme.$theming-material-components-warn ),
      typography: theme.$typography,
    ));

@include mat.typography-hierarchy(theme.$typography);
@include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Philosopher, Roboto, "Helvetica Neue", sans-serif;
}

body,
.mat-sidenav,
.mat-side-nav-content {
  background: colors.$der-e-coach-back-color;
}

.full-width {
  width: 100%;
}

.error {
  color: colors.$der-e-coach-red
}

.group-error {
  font-size: 75%;
}

.float-right {
  float: right;
}
